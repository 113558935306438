import React, { useState, useEffect } from 'react'
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	AreaChart,
	Area,
	Cell,
} from 'recharts'
import { FiUsers, FiHeart, FiClock } from 'react-icons/fi'
import { adminApi } from '../../../utils/adminApi'
import './FriendshipsSection.css'

const FriendshipsSection = () => {
	const [stats, setStats] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	// Theme colors
	const COLORS = {
		primary: '#f49e53',
		secondary: '#f4c053',
		tertiary: '#ffd78d',
		quaternary: '#ffead1',
		background: '#f8fafc',
		text: '#64748b',
		heading: '#1e293b',
	}

	useEffect(() => {
		fetchFriendshipStats()
	}, [])

	const fetchFriendshipStats = async () => {
		try {
			const data = await adminApi.getFriendshipStats()
			setStats(data)
		} catch (error) {
			console.error('Error fetching friendship stats:', error)
			setError('Failed to load friendship statistics')
		} finally {
			setLoading(false)
		}
	}

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">
					<p className="tooltip-label">{label}</p>
					<p className="tooltip-value">
						{payload[0].value.toLocaleString()} {payload[0].name}
					</p>
				</div>
			)
		}
		return null
	}

	if (loading)
		return (
			<div className="loading-container">
				<span className="loading-text">Loading friendships...</span>
			</div>
		)
	if (error) return <div className="error-message">{error}</div>

	return (
		<div className="friendships-section">
			<div className="admin-panel__stats">
				<div className="stat-card">
					<div className="stat-card__header">
						<span className="stat-card__title">Total Friendships</span>
						<div className="stat-card__icon">
							<FiHeart />
						</div>
					</div>
					<div className="stat-card__value">{stats.total.toLocaleString()}</div>
				</div>

				<div className="stat-card">
					<div className="stat-card__header">
						<span className="stat-card__title">New Friendships (24h)</span>
						<div className="stat-card__icon">
							<FiClock />
						</div>
					</div>
					<div className="stat-card__value">{stats.new24h.toLocaleString()}</div>
				</div>

				<div className="stat-card">
					<div className="stat-card__header">
						<span className="stat-card__title">Popular Category</span>
						<div className="stat-card__icon">
							<FiUsers />
						</div>
					</div>
					<div className="stat-card__value">{stats.mostPopular.category}</div>
				</div>
			</div>

			<div className="charts-grid">
				<div className="chart-container">
					<h3>Friendship Categories</h3>
					<div className="categories-list">
						{stats.categories.map((category, index) => (
							<div key={index} className="category-item">
								<div className="category-header">
									<span className="category-name">{category.category}</span>
									<span className="category-count">
										{category.count.toLocaleString()}
										<span className="percentage">({((category.count / stats.total) * 100).toFixed(1)}%)</span>
									</span>
								</div>
								<div className="category-bar-container">
									<div
										className="category-bar"
										style={{
											width: `${(category.count / stats.total) * 100}%`,
											background: `linear-gradient(to right, ${
												[COLORS.primary, COLORS.secondary, COLORS.tertiary, COLORS.quaternary][index % 4]
											}CC, ${[COLORS.primary, COLORS.secondary, COLORS.tertiary, COLORS.quaternary][index % 4]})`,
										}}
									/>
								</div>
								<div className="specific-issues">
									{category.specificIssues.map((issue, i) => (
										<div key={i} className="issue-item">
											<span>{issue.name}</span>
											<span className="issue-count">{issue.count.toLocaleString()}</span>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="chart-container">
					<h3>Daily Growth</h3>
					<ResponsiveContainer width="100%" height={300}>
						<AreaChart data={stats.dailyGrowth}>
							<defs>
								<linearGradient id="colorGrowth" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor={COLORS.primary} stopOpacity={0.1} />
									<stop offset="95%" stopColor={COLORS.primary} stopOpacity={0} />
								</linearGradient>
							</defs>
							<CartesianGrid strokeDasharray="3 3" stroke="#e2e8f0" vertical={false} />
							<XAxis
								dataKey="date"
								stroke={COLORS.text}
								tick={{ fontSize: 12, fill: COLORS.text }}
								axisLine={false}
								tickLine={false}
								dy={10}
							/>
							<YAxis
								stroke={COLORS.text}
								tick={{ fontSize: 12, fill: COLORS.text }}
								axisLine={false}
								tickLine={false}
								dx={-10}
							/>
							<Tooltip content={<CustomTooltip />} />
							<Area
								type="monotone"
								dataKey="count"
								name="New Friendships"
								stroke={COLORS.primary}
								strokeWidth={2}
								fillOpacity={1}
								fill="url(#colorGrowth)"
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	)
}

export default FriendshipsSection
