import React, { useState, useEffect } from 'react'
import { FiTrash2, FiSearch, FiAlertCircle, FiX } from 'react-icons/fi'
import { adminApi } from '../../../utils/adminApi'
import { toast } from 'react-toastify'
import './BlacklistSection.css'
import { useAuth } from '../../../context/AuthContext'
import { BASE_URL } from '../../../config'

const BlacklistSection = () => {
	const { user } = useAuth()
	const [blacklist, setBlacklist] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')

	const fetchBlacklist = async () => {
		try {
			setLoading(true)
			const data = await adminApi.getBlacklist()
			setBlacklist(data)
		} catch (error) {
			console.error('Error fetching blacklist:', error)
			setError('Failed to fetch blacklist')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchBlacklist()
	}, [])

	const handleRemoveFromBlacklist = async email => {
		if (!window.confirm('Are you sure you want to remove this email from the blacklist?')) {
			return
		}

		try {
			await adminApi.removeFromBlacklist(email)
			setBlacklist(blacklist.filter(item => item.email !== email))
			toast.success('Email removed from blacklist')
		} catch (error) {
			console.error('Error removing from blacklist:', error)
			toast.error('Failed to remove from blacklist')
		}
	}

	const filteredBlacklist = blacklist.filter(item => item.email.toLowerCase().includes(searchTerm.toLowerCase()))

	if (loading) {
		return (
			<div className="loading-container">
				<span className="loading-text">Loading blacklist...</span>
			</div>
		)
	}

	if (error) {
		return (
			<div className="error-container">
				<FiAlertCircle size={24} />
				<p>{error}</p>
				<button onClick={fetchBlacklist} className="retry-button">
					Try Again
				</button>
			</div>
		)
	}

	return (
		<div className="blacklist-section">
			<div className="blacklist-header">
				<div className="title-section">
					<FiAlertCircle className="blacklist-icon" />
					<h2>Blacklisted Users</h2>
					<span className="blacklist-count">{blacklist.length}</span>
				</div>
				<div className="search-container">
					<FiSearch className="search-icon" />
					<input
						type="text"
						placeholder="Search by email..."
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
						className="search-input"
					/>
				</div>
			</div>

			<div className="blacklist-grid">
				{filteredBlacklist.length === 0 ? (
					<div className="no-results">
						<p>No blacklisted users found</p>
					</div>
				) : (
					filteredBlacklist.map(item => (
						<div key={item.email} className="blacklist-card">
							<div className="card-header">
								<div className="email-container">
									<p className="email">{item.email}</p>
									<p className="banned-date">Banned on {new Date(item.bannedAt).toLocaleDateString()}</p>
								</div>
								<button
									onClick={() => handleRemoveFromBlacklist(item.email)}
									className="remove-button"
									title="Remove from blacklist">
									<FiX />
									Remove
								</button>
							</div>
							<p className="reason">{item.reason || 'No reason provided'}</p>
						</div>
					))
				)}
			</div>
		</div>
	)
}

export default BlacklistSection
