import React, { useState, useEffect } from 'react'
import { FiX } from 'react-icons/fi'
import './EditUserModal.css'

const EditUserModal = ({ user, onClose, onSave }) => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		profilePicture: '',
		description: '',
		FriendsAdded: 0,
		language: 'pl',
		isVerified: false,
		isAdmin: false,
		friendsAddedToday: 0,
	})

	const [errors, setErrors] = useState({})

	useEffect(() => {
		if (user) {
			setFormData({
				name: user.name || '',
				email: user.email || '',
				profilePicture: user.profilePicture || '',
				description: user.description || '',
				FriendsAdded: user.FriendsAdded || 0,
				language: user.language || 'pl',
				isVerified: user.isVerified || false,
				isAdmin: user.isAdmin || false,
				friendsAddedToday: user.friendsAddedToday || 0,
			})
		}
	}, [user])

	const handleChange = e => {
		const { name, value, type, checked } = e.target
		setFormData(prev => ({
			...prev,
			[name]: type === 'checkbox' ? checked : type === 'number' ? parseInt(value, 10) : value,
		}))
	}

	const validateForm = () => {
		const newErrors = {}

		// Validate name
		if (!formData.name.trim()) {
			newErrors.name = 'Name is required'
		}

		// Validate email
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (!formData.email.trim() || !emailRegex.test(formData.email)) {
			newErrors.email = 'Valid email is required'
		}

		setErrors(newErrors)
		return Object.keys(newErrors).length === 0
	}

	const handleSubmit = e => {
		e.preventDefault()

		if (validateForm()) {
			onSave(formData)
		}
	}

	return (
		<div className="edit-user-modal-overlay">
			<div className="edit-user-modal">
				<div className="edit-user-modal-header">
					<h2>Edit User</h2>
					<button className="close-button" onClick={onClose}>
						<FiX />
					</button>
				</div>

				<form onSubmit={handleSubmit} className="edit-user-form">
					<div className="form-group">
						<label htmlFor="name">Username</label>
						<input
							type="text"
							id="name"
							name="name"
							value={formData.name}
							onChange={handleChange}
							className={errors.name ? 'error' : ''}
						/>
						{errors.name && <span className="error-message">{errors.name}</span>}
					</div>

					<div className="form-group">
						<label htmlFor="email">Email</label>
						<input
							type="email"
							id="email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							className={errors.email ? 'error' : ''}
						/>
						{errors.email && <span className="error-message">{errors.email}</span>}
					</div>

					<div className="form-group">
						<label htmlFor="profilePicture">Profile Picture URL</label>
						<input
							type="text"
							id="profilePicture"
							name="profilePicture"
							value={formData.profilePicture}
							onChange={handleChange}
						/>
					</div>

					<div className="form-group">
						<label htmlFor="description">Description</label>
						<textarea
							id="description"
							name="description"
							value={formData.description}
							onChange={handleChange}
							rows="3"
						/>
					</div>

					<div className="form-row">
						<div className="form-group">
							<label htmlFor="FriendsAdded">Total Friends</label>
							<input
								type="number"
								id="FriendsAdded"
								name="FriendsAdded"
								value={formData.FriendsAdded}
								onChange={handleChange}
								min="0"
							/>
						</div>

						<div className="form-group">
							<label htmlFor="friendsAddedToday">Friends Added Today</label>
							<input
								type="number"
								id="friendsAddedToday"
								name="friendsAddedToday"
								value={formData.friendsAddedToday}
								onChange={handleChange}
								min="0"
							/>
						</div>
					</div>

					<div className="form-group">
						<label htmlFor="language">Language</label>
						<select id="language" name="language" value={formData.language} onChange={handleChange}>
							<option value="en">English</option>
							<option value="pl">Polish</option>
							<option value="fr">French</option>
							<option value="es">Spanish</option>
						</select>
					</div>

					<div className="form-row checkbox-row">
						<div className="form-group checkbox-group">
							<input
								type="checkbox"
								id="isVerified"
								name="isVerified"
								checked={formData.isVerified}
								onChange={handleChange}
							/>
							<label htmlFor="isVerified">Verified</label>
						</div>

						<div className="form-group checkbox-group">
							<input type="checkbox" id="isAdmin" name="isAdmin" checked={formData.isAdmin} onChange={handleChange} />
							<label htmlFor="isAdmin">Admin</label>
						</div>
					</div>

					<div className="form-actions">
						<button type="button" className="cancel-button" onClick={onClose}>
							Cancel
						</button>
						<button type="submit" className="save-button">
							Save Changes
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default EditUserModal
