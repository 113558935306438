import axios from 'axios'
import { BASE_URL } from '../config'

export const adminApi = {
	request: async (endpoint, options = {}) => {
		const adminToken = localStorage.getItem('adminToken')

		const config = {
			...options,
			headers: {
				...options.headers,
				'X-Admin-Token': adminToken,
			},
			withCredentials: true,
		}

		try {
			const response = await axios(`${BASE_URL}/api/admin${endpoint}`, config)
			return response.data
		} catch (error) {
			console.error(`Admin API Error (${endpoint}):`, error)
			throw error
		}
	},

	authenticate: async password => {
		try {
			const response = await axios.post(`${BASE_URL}/api/admin/auth`, { password })
			localStorage.setItem('adminToken', response.data.token)
			return true
		} catch (error) {
			console.error('Admin authentication error:', error)
			throw error
		}
	},

	isAuthenticated: () => {
		return !!localStorage.getItem('adminToken')
	},

	getStats: () => adminApi.request('/stats'),
	getFriendshipStats: () => adminApi.request('/friendship-stats'),
	getReports: () => adminApi.request('/reports'),
	getUsers: () => adminApi.request('/users'),
	deleteUser: userId => adminApi.request(`/users/${userId}`, { method: 'DELETE' }),
	deleteAndBlacklistUser: (userId, data) =>
		adminApi.request(`/users/${userId}/delete-and-blacklist`, {
			method: 'DELETE',
			data,
		}),
	getBlacklist: () => adminApi.request('/blacklist'),
	removeFromBlacklist: email => adminApi.request(`/blacklist/${encodeURIComponent(email)}`, { method: 'DELETE' }),
	dismissReport: reportId => adminApi.request(`/reports/${reportId}/dismiss`, { method: 'DELETE' }),
	updateUser: (userId, userData) =>
		adminApi.request(`/users/${userId}`, {
			method: 'PUT',
			data: userData,
		}),
}
