import React, { useState, useEffect } from 'react'
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	BarChart,
	Bar,
	Cell,
	Area,
	AreaChart,
	LinearGradient,
	defs,
} from 'recharts'
import { FiUsers, FiMessageSquare, FiHeart } from 'react-icons/fi'
import { adminApi } from '../../../utils/adminApi'
import './Overview.css'

const Overview = () => {
	const [stats, setStats] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	// Theme colors with gradients
	const COLORS = {
		primary: '#f49e53',
		secondary: '#f4c053',
		tertiary: '#ffd78d',
		quaternary: '#ffead1',
		background: '#f8fafc',
		text: '#64748b',
		heading: '#1e293b',
	}

	useEffect(() => {
		fetchStats()
	}, [])

	const fetchStats = async () => {
		try {
			const data = await adminApi.getStats()
			setStats(data)
		} catch (error) {
			console.error('Error fetching stats:', error)
			setError('Failed to load statistics')
		} finally {
			setLoading(false)
		}
	}

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">
					<p className="tooltip-label">{label}</p>
					<p className="tooltip-value">
						{payload[0].value.toLocaleString()} {payload[0].name}
					</p>
				</div>
			)
		}
		return null
	}

	if (loading)
		return (
			<div className="loading-container">
				<span className="loading-text">Loading overview...</span>
			</div>
		)
	if (error) return <div className="error-message">{error}</div>

	return (
		<div className="overview">
			<div className="admin-panel__stats">
				<div className="stat-card">
					<div className="stat-card__header">
						<span className="stat-card__title">Total Users</span>
						<div className="stat-card__icon">
							<FiUsers />
						</div>
					</div>
					<div className="stat-card__value">{stats.users.total.toLocaleString()}</div>
				</div>

				<div className="stat-card">
					<div className="stat-card__header">
						<span className="stat-card__title">Total Messages</span>
						<div className="stat-card__icon">
							<FiMessageSquare />
						</div>
					</div>
					<div className="stat-card__value">{stats.messages.total.toLocaleString()}</div>
				</div>

				<div className="stat-card">
					<div className="stat-card__header">
						<span className="stat-card__title">Total Friendships</span>
						<div className="stat-card__icon">
							<FiHeart />
						</div>
					</div>
					<div className="stat-card__value">{stats.friendships.total.toLocaleString()}</div>
				</div>
			</div>

			<div className="charts-grid">
				<div className="chart-container">
					<h3>User Growth</h3>
					<ResponsiveContainer width="100%" height={300}>
						<AreaChart data={stats.users.dailyGrowth}>
							<defs>
								<linearGradient id="colorGrowth" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor={COLORS.primary} stopOpacity={0.1} />
									<stop offset="95%" stopColor={COLORS.primary} stopOpacity={0} />
								</linearGradient>
							</defs>
							<CartesianGrid strokeDasharray="3 3" stroke="#e2e8f0" vertical={false} />
							<XAxis
								dataKey="date"
								stroke={COLORS.text}
								tick={{ fontSize: 12, fill: COLORS.text }}
								axisLine={false}
								tickLine={false}
								dy={10}
							/>
							<YAxis
								stroke={COLORS.text}
								tick={{ fontSize: 12, fill: COLORS.text }}
								axisLine={false}
								tickLine={false}
								dx={-10}
							/>
							<Tooltip content={<CustomTooltip />} />
							<Area
								type="monotone"
								dataKey="count"
								stroke={COLORS.primary}
								strokeWidth={2}
								fillOpacity={1}
								fill="url(#colorGrowth)"
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>

				<div className="chart-container">
					<h3>Friendship Categories</h3>
					<ResponsiveContainer width="100%" height={300}>
						<BarChart
							data={stats.friendships.categories}
							layout="vertical"
							margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
							<defs>
								{stats.friendships.categories.map((entry, index) => (
									<linearGradient key={`gradient-${index}`} id={`gradient-${index}`} x1="0" y1="0" x2="1" y2="0">
										<stop
											offset="0%"
											stopColor={[COLORS.primary, COLORS.secondary, COLORS.tertiary, COLORS.quaternary][index % 4]}
											stopOpacity={0.8}
										/>
										<stop
											offset="100%"
											stopColor={[COLORS.primary, COLORS.secondary, COLORS.tertiary, COLORS.quaternary][index % 4]}
											stopOpacity={1}
										/>
									</linearGradient>
								))}
							</defs>
							<CartesianGrid strokeDasharray="3 3" stroke="#e2e8f0" horizontal={false} />
							<XAxis
								type="number"
								stroke={COLORS.text}
								tick={{ fontSize: 12, fill: COLORS.text }}
								axisLine={false}
								tickLine={false}
								padding={{ left: 0, right: 20 }}
							/>
							<YAxis
								type="category"
								dataKey="name"
								stroke={COLORS.text}
								tick={{ fontSize: 12, fill: COLORS.text }}
								axisLine={false}
								tickLine={false}
								width={100}
							/>
							<Tooltip content={<CustomTooltip />} cursor={{ fill: '#f8fafc', opacity: 0.5 }} />
							<Bar dataKey="count" radius={[4, 4, 4, 4]} barSize={20}>
								{stats.friendships.categories.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={`url(#gradient-${index})`} />
								))}
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	)
}

export default Overview
