import React, { useState, useEffect } from 'react'
import Portal from '../Portal/Portal'
import './PWAPrompt.css'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	isPWAInstalled,
	isPWASupported,
	shouldShowPWAPrompt,
	recordPWAPromptShown,
	recordPWAPromptDismissed,
	getProgressiveDelay,
	isIOS,
	isAndroid,
	getInstallInstructions,
	getDismissCount,
	resetPWAPromptState,
} from '../../utils/pwaUtils'
import LogoOrange from '../../assets/logo.png'

// Set to true for testing, false for production
const DEBUG_MODE = false

/**
 * PWAPrompt component displays an iOS-style modal prompting users to install the PWA
 * It appears at the bottom of the screen and can be dismissed
 * The display frequency is controlled by localStorage with progressive delays
 */
const PWAPrompt = () => {
	const { t } = useTranslation()
	const [isVisible, setIsVisible] = useState(false)
	const [installInstructions, setInstallInstructions] = useState('')
	const [dismissCount, setDismissCount] = useState(0)
	const location = useLocation()

	// Load the current dismiss count when component mounts
	useEffect(() => {
		setDismissCount(getDismissCount())
	}, [])

	useEffect(() => {
		const showPrompt = () => {
			// In debug mode, always show the prompt after a delay
			if (DEBUG_MODE) {
				setInstallInstructions(getInstallInstructions())
				setTimeout(() => {
					setIsVisible(true)
				}, 2000)
				return
			}

			// In production mode, use the shouldShowPWAPrompt function
			if (!shouldShowPWAPrompt()) {
				return
			}

			// Set device-specific instructions
			setInstallInstructions(getInstallInstructions())

			// Delay showing the prompt for better user experience
			setTimeout(() => {
				setIsVisible(true)
				// Record that we showed the prompt
				recordPWAPromptShown()
			}, 2000)
		}

		showPrompt()
	}, [location.pathname]) // Check when route changes

	/**
	 * Handles the "Not now" button click
	 * Records the dismissal with a progressive delay based on how many times
	 * the user has dismissed the prompt
	 */
	const handleDismiss = () => {
		// Calculate the appropriate delay based on dismiss count
		const delayDays = getProgressiveDelay()

		// In production mode, record the dismissal
		if (!DEBUG_MODE) {
			recordPWAPromptDismissed(delayDays)
		}

		// Update the local state
		setDismissCount(prevCount => prevCount + 1)

		// Hide the prompt
		setIsVisible(false)

		// For debugging - show when the prompt will appear again
		console.log(`PWA prompt dismissed. Will show again in ${delayDays} days.`)
	}

	const handleInstall = () => {
		// Show specific instructions based on device
		alert(installInstructions)
		setIsVisible(false)
	}

	/**
	 * Resets the PWA prompt state for testing purposes
	 */
	const handleReset = () => {
		resetPWAPromptState()
		setDismissCount(0)
		setIsVisible(false)
		// Reload the page to trigger the prompt again
		window.location.reload()
	}

	if (!isVisible) {
		// In debug mode, show a floating reset button
		if (DEBUG_MODE) {
			return (
				<button className="pwa-debug-reset" onClick={handleReset} title="Reset PWA prompt state">
					🔄 Reset PWA Prompt
				</button>
			)
		}
		return null
	}

	// Customize message based on dismiss count
	const getPromptMessage = () => {
		if (dismissCount >= 5) {
			return t('pwa_prompt.messages.high_urgency')
		} else if (dismissCount >= 3) {
			return t('pwa_prompt.messages.medium_urgency')
		} else {
			return t('pwa_prompt.messages.default')
		}
	}

	return (
		<Portal>
			<div className="pwa-prompt-overlay">
				<div className="pwa-prompt-container">
					<div className="pwa-prompt-header">
						<div className="pwa-prompt-icon">
							{/* Use the app logo */}
							<div className="app-icon">
								<img src={LogoOrange} alt="FindAlikeMe Logo" className="app-logo" />
							</div>
						</div>
						<div className="pwa-prompt-title">
							<h3>FindAlikeMe</h3>
							<p>{t('pwa_prompt.title')}</p>
						</div>
						<button className="pwa-prompt-close" onClick={handleDismiss} aria-label={t('pwa_prompt.close_button_aria')}>
							<span>×</span>
						</button>
					</div>
					<div className="pwa-prompt-content">
						<p>{getPromptMessage()}</p>
						{DEBUG_MODE && (
							<div>
								<p className="debug-info">
									Dismiss count: {dismissCount} | Next delay: {getProgressiveDelay()} days
								</p>
								<button className="debug-reset-button" onClick={handleReset}>
									Reset PWA State
								</button>
							</div>
						)}
					</div>
					<div className="pwa-prompt-actions">
						<button className="pwa-prompt-cancel" onClick={handleDismiss}>
							{t('pwa_prompt.cancel_button')}
						</button>
						<button className="pwa-prompt-install" onClick={handleInstall}>
							{t('pwa_prompt.install_button')}
						</button>
					</div>
					{!isIOS() && !isAndroid() && <div className="pwa-prompt-arrow"></div>}
				</div>
			</div>
		</Portal>
	)
}

export default PWAPrompt
