/**
 * Utility functions for PWA-related functionality
 */
import i18n from 'i18next'

/**
 * Checks if the app is already installed as a PWA
 * @returns {boolean} True if the app is installed as a PWA, false otherwise
 */
export const isPWAInstalled = () => {
	// Check if the app is running in standalone mode (installed PWA)
	if (window.matchMedia('(display-mode: standalone)').matches) {
		return true
	}

	// Check for iOS "Add to Home Screen"
	if (window.navigator.standalone) {
		return true
	}

	// Check if the app was launched from the home screen
	if (document.referrer.includes('android-app://')) {
		return true
	}

	return false
}

/**
 * Checks if the browser supports PWA installation
 * @returns {boolean} True if the browser supports PWA installation, false otherwise
 */
export const isPWASupported = () => {
	// Check if the browser supports service workers
	if (!('serviceWorker' in navigator)) {
		return false
	}

	// Check if the browser supports the Web App Manifest
	const linkElements = document.querySelectorAll('link[rel="manifest"]')
	if (linkElements.length === 0) {
		return false
	}

	// Check if the browser is a modern browser that supports PWAs
	const isModernBrowser = 'fetch' in window && 'caches' in window && 'Promise' in window

	return isModernBrowser
}

/**
 * Records that the user dismissed the PWA prompt with "Nie teraz" (Not now)
 * @param {number} delayDays - Number of days to wait before showing the prompt again
 */
export const recordPWAPromptDismissed = (delayDays = 3) => {
	// Store the current time and the delay period
	const dismissalData = {
		timestamp: new Date().toISOString(),
		delayDays: delayDays,
		dismissCount: getDismissCount() + 1,
	}

	localStorage.setItem('pwaPromptDismissed', JSON.stringify(dismissalData))
}

/**
 * Gets the number of times the user has dismissed the prompt
 * @returns {number} The number of dismissals
 */
export const getDismissCount = () => {
	const dismissalData = localStorage.getItem('pwaPromptDismissed')
	if (!dismissalData) {
		return 0
	}

	try {
		const data = JSON.parse(dismissalData)
		return data.dismissCount || 0
	} catch (error) {
		console.error('Error parsing dismissal data:', error)
		return 0
	}
}

/**
 * Calculates the appropriate delay based on the number of dismissals
 * The more times a user dismisses, the longer we wait to show again
 * @returns {number} Number of days to wait
 */
export const getProgressiveDelay = () => {
	const dismissCount = getDismissCount()

	// Progressive delay strategy
	if (dismissCount <= 1) return 3 // First dismissal: wait 3 days
	if (dismissCount <= 3) return 7 // 2-3 dismissals: wait a week
	if (dismissCount <= 5) return 14 // 4-5 dismissals: wait two weeks
	return 30 // More than 5 dismissals: wait a month
}

/**
 * Checks if enough time has passed since the user dismissed the prompt
 * @returns {boolean} True if enough time has passed, false otherwise
 */
export const hasDelayPassed = () => {
	const dismissalData = localStorage.getItem('pwaPromptDismissed')
	if (!dismissalData) {
		return true // No dismissal recorded, so delay has "passed"
	}

	try {
		const data = JSON.parse(dismissalData)
		const dismissalDate = new Date(data.timestamp)
		const currentDate = new Date()

		// Calculate days passed since dismissal
		const diffTime = Math.abs(currentDate - dismissalDate)
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

		// Check if the specified delay has passed
		return diffDays >= (data.delayDays || 3)
	} catch (error) {
		console.error('Error checking dismissal delay:', error)
		return true // On error, default to showing the prompt
	}
}

/**
 * Checks if the PWA prompt should be shown based on last shown date
 * @param {number} daysInterval - Number of days to wait before showing the prompt again
 * @returns {boolean} True if the prompt should be shown, false otherwise
 */
export const shouldShowPWAPrompt = (daysInterval = 7) => {
	// If already installed, don't show the prompt
	if (isPWAInstalled()) {
		return false
	}

	// If the browser doesn't support PWAs, don't show the prompt
	if (!isPWASupported()) {
		return false
	}

	// Check if the user has dismissed the prompt and if enough time has passed
	if (!hasDelayPassed()) {
		return false
	}

	// Check when the prompt was last shown
	const lastPromptDate = localStorage.getItem('pwaPromptLastShown')

	// If never shown before, show it
	if (!lastPromptDate) {
		return true
	}

	// Calculate days since last shown
	const lastDate = new Date(lastPromptDate)
	const currentDate = new Date()
	const diffTime = Math.abs(currentDate - lastDate)
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

	// Show if it's been more than the specified interval
	return diffDays >= daysInterval
}

/**
 * Records that the PWA prompt was shown
 */
export const recordPWAPromptShown = () => {
	localStorage.setItem('pwaPromptLastShown', new Date().toISOString())
}

/**
 * Resets all PWA prompt state in localStorage
 * This is useful for testing purposes
 */
export const resetPWAPromptState = () => {
	localStorage.removeItem('pwaPromptLastShown')
	localStorage.removeItem('pwaPromptDismissed')
	console.log('PWA prompt state has been reset')
}

/**
 * Checks if the device is iOS
 * @returns {boolean} True if the device is iOS, false otherwise
 */
export const isIOS = () => {
	return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

/**
 * Checks if the device is Android
 * @returns {boolean} True if the device is Android, false otherwise
 */
export const isAndroid = () => {
	return /Android/.test(navigator.userAgent)
}

/**
 * Gets the appropriate installation instructions based on the device
 * Uses translations from the i18n system
 * @returns {string} Installation instructions
 */
export const getInstallInstructions = () => {
	if (isIOS()) {
		return i18n.t('pwa_prompt.install_instructions.ios')
	} else if (isAndroid()) {
		return i18n.t('pwa_prompt.install_instructions.android')
	} else {
		return i18n.t('pwa_prompt.install_instructions.desktop')
	}
}

/**
 * Applies PWA-specific styles to the document body
 * This helps ensure proper layout in PWA mode
 */
export const applyPWAStyles = () => {
	// Check if running in PWA mode
	const isPWA = isPWAInstalled()

	if (isPWA) {
		// Add a class to the body element to enable PWA-specific styles
		document.body.classList.add('pwa-mode')

		// Apply safe area insets for notched devices
		document.documentElement.style.setProperty('--safe-area-inset-top', 'env(safe-area-inset-top, 0px)')
		document.documentElement.style.setProperty('--safe-area-inset-bottom', 'env(safe-area-inset-bottom, 0px)')
		document.documentElement.style.setProperty('--safe-area-inset-left', 'env(safe-area-inset-left, 0px)')
		document.documentElement.style.setProperty('--safe-area-inset-right', 'env(safe-area-inset-right, 0px)')

		// Handle iOS-specific issues
		if (isIOS()) {
			document.body.classList.add('pwa-ios')

			// Fix for iOS viewport height issues
			const setIOSHeight = () => {
				const vh = window.innerHeight * 0.01
				document.documentElement.style.setProperty('--vh', `${vh}px`)
			}

			// Set initial height
			setIOSHeight()

			// Update on resize and orientation change
			window.addEventListener('resize', setIOSHeight)
			window.addEventListener('orientationchange', setIOSHeight)
		}

		// Handle Android-specific issues
		if (isAndroid()) {
			document.body.classList.add('pwa-android')
		}
	}
}
