export const BASE_URL = 'https://findalike.me'
export const FETCH_FRIENDS_URL = `${BASE_URL}/api/friends`
export const USER_INFO_URL = `${BASE_URL}/api/auth/user-info`
export const ACTIVE_RESPONSES_COUNT_URL = `${BASE_URL}/api/active-responses-count`
export const SUBMIT_DESCRIPTION_URL = `${BASE_URL}/api/submit-description`
export const SUBMIT_RESPONSE_URL = `${BASE_URL}/api/submit-response`

// Language settings
export const DEFAULT_LANGUAGE = 'pl' // Default fallback language
export const SUPPORTED_LANGUAGES = ['en', 'pl', 'fr', 'es']

// User settings endpoints
export const UPDATE_USER_SETTINGS_URL = `${BASE_URL}/api/auth/update-settings`
export const UPDATE_USER_LANGUAGE_URL = `${BASE_URL}/api/auth/update-language`

// Add more endpoints as needed
