import React from 'react'

const DashboardNav = ({ activeSection, setActiveSection }) => {
	return (
		<nav className="dashboard-nav">
			<div className="nav-header">
				<div className="nav-brand">
					<span className="nav-title">FindAlikeMe</span>
					<span className="nav-subtitle">Admin Dashboard</span>
				</div>
			</div>
			<div className="nav-menu">
				<div
					className={`nav-item ${activeSection === 'overview' ? 'active' : ''}`}
					onClick={() => setActiveSection('overview')}>
					<span className="nav-icon">
						<svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
							<rect x="3" y="3" width="7" height="7" />
							<rect x="14" y="3" width="7" height="7" />
							<rect x="14" y="14" width="7" height="7" />
							<rect x="3" y="14" width="7" height="7" />
						</svg>
					</span>
					Overview
				</div>
				<div
					className={`nav-item ${activeSection === 'users' ? 'active' : ''}`}
					onClick={() => setActiveSection('users')}>
					<span className="nav-icon">
						<svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
							<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
							<circle cx="12" cy="7" r="4" />
						</svg>
					</span>
					Users
				</div>
				<div
					className={`nav-item ${activeSection === 'friendships' ? 'active' : ''}`}
					onClick={() => setActiveSection('friendships')}>
					<span className="nav-icon">
						<svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
							<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
							<circle cx="9" cy="7" r="4" />
							<path d="M23 21v-2a4 4 0 0 0-3-3.87" />
							<path d="M16 3.13a4 4 0 0 1 0 7.75" />
						</svg>
					</span>
					Friendships
				</div>
				<div
					className={`nav-item ${activeSection === 'reports' ? 'active' : ''}`}
					onClick={() => setActiveSection('reports')}>
					<span className="nav-icon">
						<svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
							<path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
							<line x1="12" y1="9" x2="12" y2="13" />
							<line x1="12" y1="17" x2="12.01" y2="17" />
						</svg>
					</span>
					Reports
				</div>
				<div
					className={`nav-item ${activeSection === 'blacklist' ? 'active' : ''}`}
					onClick={() => setActiveSection('blacklist')}>
					<span className="nav-icon">
						<svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
							<circle cx="12" cy="12" r="10" />
							<line x1="4.93" y1="4.93" x2="19.07" y2="19.07" />
						</svg>
					</span>
					Blacklist
				</div>
			</div>
		</nav>
	)
}

export default DashboardNav
