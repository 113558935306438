import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { adminApi } from '../../utils/adminApi'
import DashboardNav from './components/DashboardNav'
import Overview from './components/Overview'
import FriendshipsSection from './components/FriendshipsSection'
import ReportsSection from './components/ReportsSection'
import UsersSection from './components/UsersSection'
import BlacklistSection from './components/BlacklistSection'
import './AdminPanel.css'

const AdminPanel = () => {
	const [activeSection, setActiveSection] = useState('overview')
	const [password, setPassword] = useState('')
	const [error, setError] = useState('')
	const navigate = useNavigate()

	// Check if already authenticated
	if (!adminApi.isAuthenticated()) {
		return (
			<div className="admin-auth-container">
				<div className="admin-auth-form">
					<h2>Admin Authentication</h2>
					{error && <div className="error-message">{error}</div>}
					<input
						type="password"
						value={password}
						onChange={e => setPassword(e.target.value)}
						placeholder="Enter admin password"
					/>
					<button
						onClick={async () => {
							try {
								await adminApi.authenticate(password)
								window.location.reload() // Reload to reinitialize admin panel
							} catch (err) {
								setError('Invalid password')
								setPassword('')
							}
						}}>
						Login
					</button>
				</div>
			</div>
		)
	}

	const renderSection = () => {
		switch (activeSection) {
			case 'overview':
				return <Overview />
			case 'friendships':
				return <FriendshipsSection />
			case 'reports':
				return <ReportsSection />
			case 'users':
				return <UsersSection />
			case 'blacklist':
				return <BlacklistSection />
			default:
				return <Overview />
		}
	}

	return (
		<div className="admin-wrapper">
			<div className="admin-dashboard">
				<DashboardNav activeSection={activeSection} setActiveSection={setActiveSection} />
				<main className="dashboard-main">
					<div className="dashboard-content">{renderSection()}</div>
				</main>
			</div>
		</div>
	)
}

export default AdminPanel
