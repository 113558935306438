import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children }) => {
	const mount = document.getElementById('portal-root')
	const elRef = useRef(null)

	if (!elRef.current) {
		elRef.current = document.createElement('div')
	}

	useEffect(() => {
		const el = elRef.current
		mount.appendChild(el)
		return () => {
			mount.removeChild(el)
		}
	}, [mount])

	return createPortal(children, elRef.current)
}

export default Portal
