// src/i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from './config'

import translationEN from './locales/en/translation.json'
import translationPL from './locales/pl/translation.json'
import translationFR from './locales/fr/translation.json'
import translationES from './locales/es/translation.json'

// Map of language codes to their full variants
const languageMap = {
	en: ['en', 'en-US', 'en-GB'],
	pl: ['pl', 'pl-PL'],
	fr: ['fr', 'fr-FR', 'fr-CA'],
	es: ['es', 'es-ES', 'es-MX', 'es-AR'],
}

const resources = {
	en: { translation: translationEN },
	pl: { translation: translationPL },
	fr: { translation: translationFR },
	es: { translation: translationES },
}

/**
 * Normalizes language code to one of our supported languages (en, pl, fr, es)
 * @param {string} langCode - The language code to normalize
 * @returns {string} - Normalized language code or undefined to allow i18next's fallback mechanism
 */
const normalizeLanguageCode = langCode => {
	// If no language code provided, return undefined to let i18next use its fallback mechanism
	if (!langCode) return undefined

	// Convert to lowercase for case-insensitive matching
	const lowerLangCode = langCode.toLowerCase()

	// First check if it's already a supported language code
	if (SUPPORTED_LANGUAGES.includes(lowerLangCode)) {
		return lowerLangCode
	}

	// Check if the language code matches any of our supported variants
	for (const [key, variants] of Object.entries(languageMap)) {
		if (variants.some(variant => lowerLangCode === variant || lowerLangCode.startsWith(variant + '-'))) {
			return key // Return the normalized code (en, pl, fr, es)
		}
	}

	// Return undefined to let i18next use its fallback mechanism
	// This is better than forcing DEFAULT_LANGUAGE as it allows proper language negotiation
	return undefined
}

// Custom language detector that properly normalizes browser language
const customDetector = {
	name: 'customNavigatorDetector',
	lookup() {
		if (typeof window === 'undefined') return undefined

		// Get browser language
		const browserLang = navigator.language || navigator.userLanguage
		if (!browserLang) return undefined

		// Normalize the detected language
		return normalizeLanguageCode(browserLang)
	},
	cacheUserLanguage(lng) {
		// Store normalized language in localStorage
		if (lng) {
			try {
				localStorage.setItem('i18nextLng', lng)
			} catch (error) {
				console.error('Error caching language:', error)
			}
		}
	},
}

// Create a custom language detector that handles the detection process in a specific order
const createCustomDetector = () => {
	return {
		type: 'languageDetector',
		async: false,
		init: () => {},
		detect: () => {
			try {
				// First check localStorage
				const savedLng = localStorage.getItem('i18nextLng')
				if (savedLng) {
					const normalized = normalizeLanguageCode(savedLng)
					if (normalized) return normalized
				}

				// Then try browser language
				const detected = customDetector.lookup()
				if (detected) return detected

				// Finally, use default language
				return DEFAULT_LANGUAGE
			} catch (error) {
				console.error('Language detection error:', error)
				return DEFAULT_LANGUAGE
			}
		},
		cacheUserLanguage: lng => {
			try {
				customDetector.cacheUserLanguage(lng)
			} catch (error) {
				console.error('Error in cacheUserLanguage:', error)
			}
		},
	}
}

i18n
	// Register the language detector
	.use(createCustomDetector())
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: DEFAULT_LANGUAGE,
		load: 'languageOnly', // Only load language without region (en instead of en-US)
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
		// Don't use the built-in detection since we're handling it ourselves
		detection: {
			order: [],
			caches: ['localStorage'],
		},
	})

// Save language preference when it changes
i18n.on('languageChanged', lng => {
	if (!lng) return

	// Normalize the language code before saving
	const normalizedLng = normalizeLanguageCode(lng) || DEFAULT_LANGUAGE
	localStorage.setItem('i18nextLng', normalizedLng)
})

export default i18n
