// src/App.js
import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from './context/AuthContext'
import { HelmetProvider } from 'react-helmet-async'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import './i18n'
import i18n from './i18n'
import { DEFAULT_LANGUAGE } from './config'
import AdminPanel from './Pages/Admin/AdminPanel'
import ContactPage from './Pages/Contact/ContactPage'
import PWAPrompt from './components/PWAPrompt/PWAPrompt'
import { applyPWAStyles } from './utils/pwaUtils'

const Login = React.lazy(() => import('./Pages/Auth/Login/Login'))
const Register = React.lazy(() => import('./Pages/Auth/Register/Register'))
const ExpertLogin = React.lazy(() => import('./Pages/Auth/ExpertLogin/ExpertLogin'))
const ExpertRegister = React.lazy(() => import('./Pages/Auth/ExpertRegister/ExpertRegister'))
const EditProfile = React.lazy(() => import('./Pages/Chat/EditProfile/EditProfile'))
const Chat = React.lazy(() => import('./Pages/Chat/ChatLayout'))
const Home = React.lazy(() => import('./Pages/Home/Home'))
const ForgotPassword = React.lazy(() => import('./Pages/Auth/PasswordChange/ForgotPassword/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./Pages/Auth/PasswordChange/ResetPassword/ResetPassword'))
const VerificationPage = React.lazy(() => import('./Pages/Auth/Verification/VerificationPage'))
const PostPage = React.lazy(() => import('./Pages/MindShare/PostPage'))
const MindShareCategoryView = React.lazy(() => import('./Pages/MindShare/MindShareCategoryView'))
const Changelog = React.lazy(() => import('./Pages/Changelog/Changelog'))

const PrivacyPolicy = React.lazy(() => import('./Pages/PrivacyPolicy/PrivacyPolicy'))

// Loading component for suspense fallback
const LoadingSpinner = () => (
	<div role="status" aria-label="Loading" className="spinner-container">
		<div className="spinner"></div>
	</div>
)

const App = () => {
	const { user } = useAuth()
	const location = useLocation()
	const isHomePage = location.pathname === '/'
	const [languageInitialized, setLanguageInitialized] = useState(false)

	// Apply PWA-specific styles on app load
	useEffect(() => {
		applyPWAStyles()
	}, [])

	// Initialize language on app load (only once)
	useEffect(() => {
		// This function ensures language is properly initialized
		const initializeLanguage = async () => {
			try {
				// Check if i18n is already initialized with a valid language
				const currentLanguage = i18n.language

				// Log current language state for debugging
				console.log('Current i18n language state:', currentLanguage)

				// If i18n hasn't loaded a language yet or has an invalid language, set the default
				if (!currentLanguage || currentLanguage === 'cimode') {
					console.log('Setting default language:', DEFAULT_LANGUAGE)
					await i18n.changeLanguage(DEFAULT_LANGUAGE)
				}

				// Log the initialized language for debugging
				console.log('Language initialized:', i18n.language)

				// Mark language as initialized
				setLanguageInitialized(true)
			} catch (error) {
				console.error('Error initializing language:', error)
				// Fallback to default language in case of error
				await i18n.changeLanguage(DEFAULT_LANGUAGE)
				setLanguageInitialized(true)
			}
		}

		// Initialize language immediately without timeout
		initializeLanguage()

		// No cleanup needed since we're not using a timeout anymore
	}, []) // Run only once on mount

	// Update language when user changes (if user has a language preference)
	// Only run this effect after initial language is set to prevent race conditions
	useEffect(() => {
		if (!languageInitialized) return

		if (user?.language) {
			// User's language preference takes precedence over other settings
			console.log('Setting user preferred language:', user.language)
			i18n.changeLanguage(user.language)
		}
	}, [user, languageInitialized])

	useEffect(() => {
		// Handle viewport height for mobile browsers
		const setVh = () => {
			const vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', `${vh}px`)
		}

		setVh()
		window.addEventListener('resize', setVh)

		return () => window.removeEventListener('resize', setVh)
	}, [])

	const ProtectedRoute = ({ children }) => {
		if (!user) return <Navigate to="/register" replace />
		// Treat undefined isVerified as true for legacy users
		const isVerified = user.isVerified === undefined ? true : user.isVerified
		// Redirect to verification page if user is not verified
		if (!isVerified) return <Navigate to="/verify" replace />
		return children
	}

	// Route specifically for users who need to verify their account
	const VerificationRoute = ({ children }) => {
		if (!user) return <Navigate to="/register" replace />
		// Treat undefined isVerified as true for legacy users
		const isVerified = user.isVerified === undefined ? true : user.isVerified
		// If already verified, redirect to chat
		if (isVerified) return <Navigate to="/chat" replace />
		return children
	}

	const ProtectedAdminRoute = ({ children }) => {
		const { user } = useAuth()
		const navigate = useNavigate()

		useEffect(() => {
			if (!user) {
				navigate('/login')
				return
			}

			if (user.isAdmin !== true) {
				console.log('Access denied - not admin:', user)
				navigate('/chat')
				toast.error('Brak dostępu do panelu administratora')
				return
			}
		}, [user, navigate])

		return children
	}

	// If language is not initialized yet, show a minimal loading indicator
	if (!languageInitialized) {
		return <LoadingSpinner />
	}

	return (
		<HelmetProvider>
			<div className={isHomePage ? 'div-home' : 'div-main'}>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					zIndex={9999}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
				<React.Suspense fallback={<LoadingSpinner />}>
					<Routes>
						{/* Public routes */}
						<Route path="/login" element={!user ? <Login /> : <Navigate to="/chat" replace />} />
						<Route path="/register" element={!user ? <Register /> : <Navigate to="/chat" replace />} />

						{/* Verification route */}
						<Route
							path="/verify"
							element={
								<VerificationRoute>
									<VerificationPage />
								</VerificationRoute>
							}
						/>

						{/* Expert routes */}
						<Route path="/expert-login" element={!user ? <ExpertLogin /> : <Navigate to="/chat" replace />} />
						<Route path="/expert-register" element={!user ? <ExpertRegister /> : <Navigate to="/chat" replace />} />

						{/* Protected routes */}
						<Route
							path="/edit-profile"
							element={
								<ProtectedRoute>
									<EditProfile />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/chat"
							element={
								<ProtectedRoute>
									<Chat />
								</ProtectedRoute>
							}
						/>

						{/* MindShare routes */}
						{/* <Route
							path="/mindshare/:category/:slug"
							element={
								<ProtectedRoute>
									<PostPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/mindshare/:category"
							element={
								<ProtectedRoute>
									<MindShareCategoryView />
								</ProtectedRoute>
							}
						/> */}

						{/* Password reset routes */}
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/reset-password/:token" element={<ResetPassword />} />

						{/* Home route */}
						<Route path="/" element={<Home />} />

						{/* Admin route */}
						<Route path="/admin" element={<AdminPanel />} />

						{/* Contact route */}
						<Route path="/contact" element={<ContactPage />} />

						{/* Privacy Policy route */}
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />

						{/* Changelog route */}
						<Route path="/changelog" element={<Changelog />} />

						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</React.Suspense>

				<PWAPrompt />
			</div>
		</HelmetProvider>
	)
}

export default App
