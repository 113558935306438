import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { gsap } from 'gsap'
import { useTranslation } from 'react-i18next'
import './Navbar.css'
import { useAuth } from '../../context/AuthContext'
import { IoLanguage } from 'react-icons/io5'
import { FaInfoCircle, FaEnvelope, FaHistory } from 'react-icons/fa'
import axiosInstance from 'axios'
import { io } from 'socket.io-client'
import { BASE_URL } from '../../config'

import Btn2 from '../Buttons/btn2'
import Btn3 from '../Buttons/btn3'

const Navbar = ({ variant }) => {
	const [scrolled, setScrolled] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
	const menuRef = useRef(null)
	const hamburgerRef = useRef(null)
	const navigate = useNavigate()
	const { user, logout } = useAuth()
	const { t, i18n } = useTranslation()
	const [isLangMenuOpen, setIsLangMenuOpen] = useState(false)
	const langMenuRef = useRef(null)
	const [currentLang, setCurrentLang] = useState(i18n.language || 'en')
	const [userStats, setUserStats] = useState({ totalUsers: 0, onlineUsers: 0 })

	const socket = useMemo(() => {
		if (user) {
			return io(BASE_URL, {
				withCredentials: true,
				auth: {
					userId: user._id,
				},
			})
		}
		return null
	}, [user])

	useEffect(() => {
		const handleScroll = () => {
			setScrolled(window.scrollY > 50)
		}

		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768)
		}

		window.addEventListener('scroll', handleScroll)
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		if (isOpen) {
			gsap.to(menuRef.current, {
				x: '0%',
				duration: 0.15,
				ease: 'none',
				onComplete: () => {
					gsap.to('.nav-item', {
						opacity: 1,
						y: 0,
						stagger: 0.02,
						ease: 'none',
						duration: 0.1,
					})
				},
			})
		} else {
			gsap.to('.nav-item', {
				opacity: 0,
				y: 0,
				duration: 0.1,
				ease: 'none',
				onComplete: () => {
					gsap.to(menuRef.current, {
						x: '100%',
						duration: 0.15,
						ease: 'none',
					})
				},
			})
		}
	}, [isOpen])

	const toggleMenu = () => {
		setIsOpen(!isOpen)
		const tl = gsap.timeline()
		if (!isOpen) {
			tl.to('.hamburger-line:nth-child(1)', { y: 8, duration: 0.05 })
				.to('.hamburger-line:nth-child(3)', { y: -8, duration: 0.05 }, '<')
				.to('.hamburger-line:nth-child(2)', { opacity: 0, duration: 0.05 }, '<')
				.to('.hamburger-line:nth-child(1)', { rotation: 45, duration: 0.05 })
				.to('.hamburger-line:nth-child(3)', { rotation: -45, duration: 0.05 }, '<')
		} else {
			tl.to('.hamburger-line:nth-child(1)', { rotation: 0, duration: 0.05 })
				.to('.hamburger-line:nth-child(3)', { rotation: 0, duration: 0.05 }, '<')
				.to('.hamburger-line:nth-child(2)', { opacity: 1, duration: 0.05 })
				.to('.hamburger-line:nth-child(1)', { y: 0, duration: 0.05 })
				.to('.hamburger-line:nth-child(3)', { y: 0, duration: 0.05 }, '<')
		}
	}

	const handleLogout = async () => {
		try {
			if (socket) {
				socket.disconnect()
			}
			await logout()
			navigate('/')
		} catch (error) {
			console.error('Logout error:', error)
		}
	}

	const renderButtons = () => {
		if (!user) {
			return (
				<>
					<Link to="/login" className="btn2">
						<Btn2 text={t('navbar.buttons.login')} />
					</Link>
					<Link to="/register" className="btn3">
						<Btn3 text={t('navbar.buttons.start_chatting')} />
					</Link>
				</>
			)
		} else {
			return (
				<>
					<div onClick={handleLogout} className="btn2">
						<Btn2 text={t('navbar.buttons.logout')} />
					</div>
					<Link to="/chat" className="btn3">
						<Btn3 text={t('navbar.buttons.start_chatting')} />
					</Link>
				</>
			)
		}
	}

	const handleNavClick = async (e, path) => {
		e.preventDefault()

		// If it's a direct navigation path (not logout or scroll)
		if (path.startsWith('/')) {
			navigate(path)
			// Close menu immediately
			setIsOpen(false)
			return
		}

		// Handle special cases
		if (path === '/logout') {
			await handleLogout()
			setIsOpen(false)
		} else if (path === '#how-it-works') {
			setIsOpen(false)
			setTimeout(() => {
				document.getElementById('how-it-works')?.scrollIntoView({ behavior: 'smooth' })
			}, 100)
		} else if (path === 'mailto') {
			window.location.href = 'mailto:findalikeme@gmail.com'
			setIsOpen(false)
		}
	}

	const handleLanguageChange = lang => {
		i18n.changeLanguage(lang)
		setCurrentLang(lang)
		setIsLangMenuOpen(false)
	}

	useEffect(() => {
		const handleClickOutside = event => {
			if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
				setIsLangMenuOpen(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [])

	const LanguageSelector = () => {
		const [dropdownOpen, setDropdownOpen] = useState(false)
		const dropdownRef = useRef(null)
		const { t, i18n } = useTranslation()
		const [currentLang, setCurrentLang] = useState(i18n.language || 'en')

		const languages = [
			{ code: 'en', name: 'English', flag: '🇬🇧' },
			{ code: 'pl', name: 'Polski', flag: '🇵🇱' },
			{ code: 'fr', name: 'Français', flag: '🇫🇷' },
			{ code: 'es', name: 'Español', flag: '🇪🇸' },
		]

		useEffect(() => {
			const handleClickOutside = event => {
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					setDropdownOpen(false)
				}
			}

			if (dropdownOpen) {
				document.addEventListener('mousedown', handleClickOutside)
			}

			return () => {
				document.removeEventListener('mousedown', handleClickOutside)
			}
		}, [dropdownOpen])

		const handleLanguageSelect = langCode => {
			i18n.changeLanguage(langCode)
			setCurrentLang(langCode)
			setTimeout(() => {
				setDropdownOpen(false)
			}, 100)
		}

		return (
			<div className="language-selector" ref={dropdownRef}>
				<button className="language-button" onClick={() => setDropdownOpen(!dropdownOpen)} aria-expanded={dropdownOpen}>
					<IoLanguage className="lang-icon" />
					<span>{currentLang.toUpperCase()}</span>
				</button>

				{dropdownOpen && (
					<>
						<div className="language-menu-backdrop" onClick={() => setDropdownOpen(false)} />
						<div className="language-menu">
							{languages.map(({ code, name, flag }) => (
								<button
									key={code}
									className={`language-option ${currentLang === code ? 'active' : ''}`}
									onClick={() => handleLanguageSelect(code)}>
									<span className="flag">{flag}</span>
									<span className="lang-name">{name}</span>
									{currentLang === code && <span className="check-icon">✓</span>}
								</button>
							))}
						</div>
					</>
				)}
			</div>
		)
	}

	useEffect(() => {
		const fetchUserStats = async () => {
			try {
				const response = await axiosInstance.get(`${BASE_URL}/api/auth/user-stats`)
				setUserStats(response.data)
			} catch (error) {
				console.error('Error fetching user stats:', error)
			}
		}

		fetchUserStats()

		if (socket) {
			socket.on('userStatusUpdate', ({ onlineUsers }) => {
				setUserStats(prev => ({
					...prev,
					onlineUsers,
				}))
			})

			return () => {
				socket.off('userStatusUpdate')
			}
		}
	}, [socket])

	const UserStats = ({ className }) => (
		<div className={`user-stats ${className}`}>
			<div className="stat-item total">
				<span className="stat-value">{userStats.totalUsers}</span>
				<span className="stat-label">{t('user_stats.total')}</span>
			</div>
		</div>
	)

	return (
		<>
			<nav className={`navbar ${scrolled && !isMobile ? 'scrolled' : ''}`}>
				<div
					className={`nav__container ${scrolled && !isMobile ? 'scrolled' : ''} ${variant === 2 ? 'variant-2' : ''}`}>
					<UserStats className="desktop-stats" />
					<div className="nav-center desktop-menu">
						<ul>
							<li>
								<Link to="#how-it-works" onClick={e => handleNavClick(e, '#how-it-works')}>
									<FaInfoCircle className="nav-icon" /> {t('navbar.menu.how_it_works')}
								</Link>
							</li>

							<li>
								<Link to="/contact" onClick={e => handleNavClick(e, '/contact')}>
									<FaEnvelope className="nav-icon" /> {t('navbar.menu.contact')}
								</Link>
							</li>

							<li>
								<Link to="/changelog" onClick={e => handleNavClick(e, '/changelog')}>
									<FaHistory className="nav-icon" /> Changelog
								</Link>
							</li>
							{/* <li>
								<Link to="/blog" onClick={e => handleNavClick(e, '/blog')}>
									Blog
								</Link>
							</li>
							<li>
								<Link to="/tools" onClick={e => handleNavClick(e, '/tools')}>
									{t('navbar.menu.tools')}
								</Link>
							</li> */}
						</ul>
					</div>
					<div className="nav-right desktop-menu">
						<LanguageSelector />
						{renderButtons()}
					</div>
					<div className={`hamburger ${isOpen ? 'menu-open' : ''}`} ref={hamburgerRef} onClick={toggleMenu}>
						<div className="hamburger-line"></div>
						<div className="hamburger-line"></div>
						<div className="hamburger-line"></div>
					</div>
				</div>
				<div className={`mobile-menu ${isOpen ? 'open' : ''}`} ref={menuRef}>
					<ul className="mobile-nav-links">
						<li className="nav-item">
							<Link to="#how-it-works" onClick={e => handleNavClick(e, '#how-it-works')}>
								<FaInfoCircle className="nav-icon" /> {t('navbar.menu.how_it_works')}
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/contact" onClick={e => handleNavClick(e, '/contact')}>
								<FaEnvelope className="nav-icon" /> {t('navbar.menu.contact')}
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/changelog" onClick={e => handleNavClick(e, '/changelog')}>
								<FaHistory className="nav-icon" /> Changelog
							</Link>
						</li>
					</ul>

					<div className="mobile-menu-footer">
						{!user && (
							<div className="mobile-buttons">
								<Link to="/login" className="mobile-btn" onClick={e => handleNavClick(e, '/login')}>
									{t('navbar.buttons.login')}
								</Link>
								<Link to="/register" className="mobile-btn primary" onClick={e => handleNavClick(e, '/register')}>
									{t('navbar.buttons.start_chatting')}
								</Link>
							</div>
						)}

						{user && (
							<div className="mobile-buttons">
								<Link to="/chat" className="mobile-btn" onClick={e => handleNavClick(e, '/chat')}>
									{t('navbar.buttons.start_chatting')}
								</Link>
								<Link to="/logout" className="mobile-btn primary" onClick={e => handleNavClick(e, '/logout')}>
									{t('navbar.buttons.logout')}
								</Link>
							</div>
						)}
					</div>
				</div>
			</nav>

			{isMobile && (
				<div className="mobile-language-selector-wrapper">
					<LanguageSelector />
				</div>
			)}

			{isMobile && (
				<div className="mobile-stats-wrapper">
					<UserStats className="mobile-stats" />
				</div>
			)}
		</>
	)
}

export default Navbar
